import { AxisLeft, AxisBottom } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { scaleLinear, scaleBand, scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { SeriesPoint } from '@visx/shape/lib/types';
import { BarStack } from '@visx/shape';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { timeParse, timeFormat } from '@visx/vendor/d3-time-format';
import { localPoint } from '@visx/event';
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { getColorsByPlatform } from 'utils/getColorsRange';

export interface PlatformStreams {
  [key: string]: string | number;
}

type TooltipData = {
  bar: SeriesPoint<PlatformStreams>;
  key: string;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

export type BarStackProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
};

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

interface DataObject {
  [key: string]: number | string;
}

function sumAndFindTop(data: DataObject[]): number {
  let sums: number[] = [];

  data.forEach((obj) => {
    let sum = 0;
    for (let key in obj) {
      if (typeof obj[key] === 'number' && key !== 'date') {
        sum += obj[key] as number;
      }
    }
    sums.push(sum);
  });

  const topValue = Math.max(...sums);
  return topValue;
}

const parseDate = timeParse('%Y-%m-%d');
const format = timeFormat('%b %d');
const formatDate = (date: string) => format(parseDate(date) as Date);
const getDate = (d: PlatformStreams) => d.date;

let tooltipTimeout: number;

export default function StreamsStackedBar({
  width,
  platformStreams,
}: {
  width: number;
  platformStreams: PlatformStreams[];
}) {
  const [data, setData] = useState<PlatformStreams[]>([]);

  useEffect(() => {
    if (platformStreams) {
      setData(platformStreams);
    }
  }, [platformStreams]);

  const margin = { top: 0, bottom: 0, left: 0, right: 0 };
  const height = 300;

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<TooltipData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const xMax = width;
  const yMax = height - 30;

  if (!data.length) return <Box>No data</Box>;

  const keys = Object.keys((data || [])[0]).filter((d) => d !== 'date') as string[];
  const maxStreams = sumAndFindTop(data);
  const minStreams = 0;

  const streamsScale = scaleLinear<number>({
    domain: [minStreams, maxStreams],
    nice: true,
    range: [yMax, 0],
  });

  const dateScale = scaleBand<string>({
    domain: data.map(getDate) as string[],
    padding: 0.2,
  });

  const barsDateScale = scaleBand<string>({
    domain: data.map(getDate) as string[],
    padding: 0.2,
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: keys.map((key) => getColorsByPlatform[key]),
  });

  dateScale.rangeRound([35, xMax]);
  barsDateScale.rangeRound([40, xMax]);
  streamsScale.range([yMax, 10]);

  const leftAxisTotalTicks = 5;

  return (
    <div style={{ position: 'relative' }}>
      <svg ref={containerRef} width={width} height={height} style={{ borderWidth: 1 }}>
        <GridRows
          scale={streamsScale}
          width={xMax}
          height={yMax}
          left={55}
          lineStyle={{
            stroke: '#DFE1E6',
            strokeLinecap: 'round',
            strokeWidth: 1,
          }}
          strokeDasharray="2, 4"
          numTicks={leftAxisTotalTicks}
        />

        <Group left={4} top={margin.top}>
          <BarStack<PlatformStreams, string>
            data={data}
            keys={keys}
            x={getDate}
            xScale={barsDateScale}
            yScale={streamsScale}
            color={colorScale}
          >
            {(barStacks) => {
              return barStacks.map((barStack) => {
                return barStack.bars.map((bar) => {
                  return (
                    <rect
                      key={`bar-stack-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      fill={bar.color}
                      height={bar.height}
                      // width={bar.width > 25 ? 25 : bar.width}
                      width={bar.width}
                      onMouseLeave={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                      onMouseMove={(event) => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        const eventSvgCoords = localPoint(event);
                        const left = bar.x + bar.width / 2;
                        showTooltip({
                          tooltipData: bar,
                          tooltipTop: eventSvgCoords?.y,
                          tooltipLeft: left,
                        });
                      }}
                    />
                  );
                });
              });
            }}
          </BarStack>
        </Group>
        <AxisLeft
          scale={streamsScale}
          stroke="#000000"
          numTicks={leftAxisTotalTicks}
          left={15}
          hideAxisLine
          hideTicks
          tickLabelProps={() => ({
            fill: '#555',
            fontSize: 12,
            dx: '-0.5em',
            dy: '0.25em',
          })}
        />

        <AxisBottom
          top={yMax + margin.top}
          scale={dateScale}
          tickFormat={formatDate}
          numTicks={8}
          stroke={'#AAB5BB'}
          tickStroke={'#AAB5BB'}
          left={25}
          hideTicks
          tickLabelProps={{
            fill: '#555',
            fontSize: 12,
            textAnchor: 'end',
          }}
        />
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div style={{ color: colorScale(tooltipData.key) }}>
            <strong>{tooltipData.key}</strong>
          </div>
          <div>{tooltipData.bar.data[tooltipData.key]} streams</div>
          <div>
            {/* @ts-ignore */}
            <small>{formatDate(getDate(tooltipData.bar.data))}</small>
          </div>
        </TooltipInPortal>
      )}
    </div>
  );
}
