import { Grid, Avatar, Box, CircularProgress, Tooltip } from '@mui/material';
import { IDeal, IRecoupment } from 'hooks/useUserData';
import { colors } from 'styles/colors';
import star from 'assets/icons/star.svg';
import {
  StyledLabel,
  StyledValue,
  StyledAmount,
  StyledHeading,
} from '../sections/styled-components';
import { formatValueToDollars } from 'utils/helpers';
import Text from 'components/text';
import { Info } from 'lucide-react';
import DialogModal from 'components/dialog-modal';
import PurpleButton from 'components/purple-button';
import { useCallback, useEffect, useState } from 'react';
import Row from 'components/row';
import api from 'utils/api';
import { RecoupmentStatus } from '../sections/admin-section/Renewals';

const InvitedDealItem: React.FC<IDeal> = ({
  id,
  images,
  amount,
  artistName,
  recoupments,
  lengthOfCommitment,
}) => {
  const [recoupmentData, setRecoupmentData] = useState<IRecoupment>();

  const [confirmedRenewStatus, setConfirmedRenewStatus] = useState<
    'idle' | 'pending' | 'confirmed'
  >('idle');

  const [renewableDeal, setRenewableDeal] = useState<{ id: number; totalAdvance: number } | null>(
    null
  );

  useEffect(() => {
    if (recoupments) {
      setRecoupmentData(recoupments);
    }
  }, [recoupments]);

  const handleConfirmRenew = useCallback(() => {
    setConfirmedRenewStatus('pending');
    api.recoupments
      .statusUpdate({
        id: renewableDeal?.id,
        data: { status: RecoupmentStatus.APPROVED },
      })
      .then(() => {
        setConfirmedRenewStatus('confirmed');
        if (recoupmentData) {
          setRecoupmentData({
            ...recoupmentData,
            status: RecoupmentStatus.APPROVED,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [renewableDeal, recoupmentData]);

  const handleCloseModal = useCallback(() => {
    setRenewableDeal(null);
  }, []);

  const isSignedArtist = (lengthOfCommitment || 0) > 0 && (amount || 0) > 0;

  return (
    <Grid
      container
      sx={{
        padding: '32px',
        marginBottom: '32px',
        border: `1px solid ${colors.background.light.b4}`,
        borderRadius: '8px',
        boxShadow: '2px 1px 15px rgba(44, 59, 70, 0.2)',
        transition: 'box-shadow 0.4s ease-in-out',
        '&:hover': {
          boxShadow: '18px 18px 10px rgb(44 59 70 / 20%)',
        },
      }}
    >
      <Grid container item md={2} xs={12}>
        <Avatar src={images[0]?.url || star} alt="avatar" sx={{ width: '88px', height: '88px' }} />
      </Grid>
      <Grid container flexDirection="column" item md={10}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingBottom: '16px',
            '& svg': {
              width: '16px',
              height: '16px',
              transform: 'rotate(180deg)',
            },
            alignItems: { xs: 'flex-end', md: 'center' },
          }}
        >
          <StyledHeading sx={{ paddingTop: { xs: '20px', md: '0' } }}>
            {artistName || '-'}
          </StyledHeading>
        </Grid>

        <Grid
          container
          alignItems="center"
          sx={{
            padding: '16px 0',
            borderTop: `1px solid ${colors.background.light.b4}`,
            borderBottom: `1px solid ${colors.background.light.b4}`,
          }}
        >
          {isSignedArtist ? (
            <>
              {recoupmentData ? (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box
                        bgcolor="#FAFBFC"
                        border={1}
                        borderColor="#eee"
                        borderRadius={4}
                        textAlign="center"
                        padding={2}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          gap={1}
                          marginTop={1}
                          marginBottom={4}
                        >
                          <Text fontSize={20}>Payment Breakdown</Text>
                        </Box>
                        <Box position="relative" display="inline-block" marginBottom={4}>
                          <CircularProgress
                            variant="determinate"
                            className="progress-meter"
                            sx={{
                              zIndex: 2,
                              position: 'relative',
                              color: '#1ACC81',
                            }}
                            size={220}
                            thickness={10}
                            value={
                              recoupmentData?.estimatedRemainingDuration < 1
                                ? 100
                                : recoupmentData
                                ? (recoupmentData.amountRecouped / recoupmentData.totalAdvance) *
                                  100
                                : 0
                            }
                          />
                          <CircularProgress
                            variant="determinate"
                            className="progress-meter"
                            sx={{
                              left: 0,
                              position: 'absolute',
                              color: '#dde5ee',
                            }}
                            size={220}
                            thickness={10}
                            value={100}
                          />
                        </Box>
                        <Box
                          display="flex"
                          gap={1}
                          alignItems="center"
                          justifyContent="center"
                          marginBottom={2}
                        >
                          <Box width={18} height={18} borderRadius={9} bgcolor="#dde5ee" />
                          <Text fontSize={16} color="#616978">
                            Total Advance {formatValueToDollars(recoupmentData?.totalAdvance || 0)}
                          </Text>
                        </Box>
                        <Box
                          display="flex"
                          gap={1}
                          alignItems="center"
                          justifyContent="center"
                          marginBottom={2}
                        >
                          <Box width={18} height={18} borderRadius={9} bgcolor="#1ACC81" />
                          <Text fontSize={16} color="#616978">
                            Amount Recouped{' '}
                            {formatValueToDollars(recoupmentData?.amountRecouped || 0)}
                          </Text>
                        </Box>
                        <Box paddingX={4} paddingY={2}>
                          <Text fontSize={18} color="#333" display="inline">
                            {recoupmentData?.estimatedRemainingDuration < 1
                              ? 'You have recouped!'
                              : `We estimate you'll recoup in around ${recoupmentData?.estimatedRemainingDuration} months.`}
                          </Text>
                          <Box
                            component={'span'}
                            display="inline-block"
                            width={10}
                            height={10}
                            marginLeft={0.6}
                            position="relative"
                          >
                            <Box component="span" position="absolute" top={-3} left={0}>
                              <Tooltip
                                title="This calculation is based on historical payment averages"
                                arrow
                              >
                                <Info color="#888" size={18} />
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      {recoupmentData.status === 'pending' && (
                        <Box
                          bgcolor="#FAFBFC"
                          border={1}
                          borderColor="#eee"
                          borderRadius={4}
                          textAlign="center"
                          padding={2}
                          marginTop={2}
                        >
                          <Box paddingBottom={2}>
                            <Text fontSize={19}>
                              You qualify for{' '}
                              {formatValueToDollars(recoupmentData.totalAdvance * 1.35)} advance!
                            </Text>
                          </Box>
                          <PurpleButton
                            onClick={(e) => {
                              e.preventDefault();
                              setRenewableDeal({
                                id: recoupmentData.id,
                                totalAdvance: recoupmentData.totalAdvance,
                              });
                            }}
                            sx={{ width: '70%' }}
                          >
                            <Text color="#fff" fontWeight="bold">
                              Renew My Deal
                            </Text>
                          </PurpleButton>
                        </Box>
                      )}

                      {recoupmentData.status === 'approved' && (
                        <Box
                          bgcolor="#FAFBFC"
                          border={1}
                          borderColor="#eee"
                          borderRadius={4}
                          textAlign="center"
                          padding={2}
                          marginTop={2}
                        >
                          <Box>
                            <Text fontSize={19}>
                              Deal renewal request submitted! We will contact you shortly.
                            </Text>
                          </Box>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} alignItems="center">
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        paddingTop={1}
                        height="100%"
                        justifyContent="center"
                      >
                        <Box>
                          <StyledLabel>Total Advance:</StyledLabel>
                          <StyledValue paddingRight="12px">
                            <Text component="span">
                              {formatValueToDollars(recoupmentData?.totalAdvance || 0)}
                            </Text>
                          </StyledValue>
                        </Box>
                        <Box borderBottom={1} borderColor="#dde5ee" />

                        <Box>
                          <StyledLabel>Amount Recouped:</StyledLabel>
                          <StyledValue paddingRight="12px">
                            <Text component="span">
                              {formatValueToDollars(recoupmentData?.amountRecouped || 0)}
                            </Text>
                          </StyledValue>
                        </Box>
                        <Box borderBottom={1} borderColor="#dde5ee" />

                        <Box>
                          <StyledLabel>Term Length:</StyledLabel>
                          <StyledValue paddingRight="12px">
                            <Text component="span">{recoupmentData?.termLength || 0} years</Text>
                          </StyledValue>
                        </Box>
                        <Box borderBottom={1} borderColor="#dde5ee" />

                        <Box>
                          <StyledLabel>Recoupment Rate:</StyledLabel>
                          <StyledValue paddingRight="12px">
                            <Text component="span">{recoupmentData?.recoupmentRate || 0.0}</Text>
                          </StyledValue>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        marginBottom={2}
                        padding={2}
                        bgcolor="#eee"
                        fontSize={18}
                        borderRadius={1.2}
                      >
                        <Text>You'r recoupment data will be updated soon! Please check later.</Text>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    flexDirection="column"
                    item
                    xs={12}
                    md={4}
                    sx={{ marginBottom: { xs: '15px', md: '0' } }}
                  >
                    <StyledLabel>Amount:</StyledLabel>
                    <StyledValue paddingRight="12px">
                      <StyledAmount as="span">{formatValueToDollars(amount ?? 0)}</StyledAmount>
                    </StyledValue>
                  </Grid>
                  <Grid
                    container
                    flexDirection="column"
                    item
                    xs={12}
                    md={4}
                    sx={{ marginBottom: { xs: '15px', md: '0' } }}
                  >
                    <StyledLabel>Term Length:</StyledLabel>
                    <StyledValue paddingRight="12px">
                      <Text component="span">{Number(lengthOfCommitment) * 12} months</Text>
                    </StyledValue>
                  </Grid>
                </>
              )}
            </>
          ) : (
            <Grid
              container
              flexDirection="column"
              item
              xs={12}
              sx={{ marginBottom: { xs: '15px', md: '0' } }}
            >
              <Box padding={2} bgcolor="#eee" fontSize={18} borderRadius={1.2}>
                <Text>We are revewing your reports and your application is pending.</Text>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <DialogModal withActions={false} open={renewableDeal !== null} handleClose={handleCloseModal}>
        {confirmedRenewStatus === 'idle' && (
          <>
            <Box paddingBottom={3}>
              <Text fontSize={26}>Deal Renew Offer</Text>
            </Box>

            <Box>
              <Row
                title={
                  <Text fontSize={20} lineHeight={1.3}>
                    Eligible Advance
                  </Text>
                }
                value={
                  <Text fontSize={20} lineHeight={1.3}>
                    {formatValueToDollars((renewableDeal?.totalAdvance || 0) * 1.35)}
                  </Text>
                }
              />
              <Row
                title={
                  <Text fontSize={20} lineHeight={1.3}>
                    Eligible Term Length
                  </Text>
                }
                value={
                  <Text fontSize={20} lineHeight={1.3}>
                    3 years
                  </Text>
                }
              />
              <Box paddingTop={2}>
                <Text fontSize={20} lineHeight={1.3}>
                  Please confirm if you would like to proceed by clicking the confirm button below.
                </Text>
              </Box>
              <Box paddingTop={4}>
                <PurpleButton onClick={handleConfirmRenew}>
                  <Text color="#fff" fontWeight="bold">
                    Confirm
                  </Text>
                </PurpleButton>
              </Box>
            </Box>
          </>
        )}

        {confirmedRenewStatus === 'pending' && (
          <>
            <Box paddingBottom={3}>
              <Text fontSize={26}>Deal Renew Offer</Text>
            </Box>

            <Box>
              <Row
                title={
                  <Text fontSize={20} lineHeight={1.3}>
                    Eligible Advance
                  </Text>
                }
                value={
                  <Text fontSize={20} lineHeight={1.3}>
                    {formatValueToDollars((renewableDeal?.totalAdvance || 0) * 1.35)}
                  </Text>
                }
              />
              <Row
                title={
                  <Text fontSize={20} lineHeight={1.3}>
                    Eligible Term Length
                  </Text>
                }
                value={
                  <Text fontSize={20} lineHeight={1.3}>
                    3 years
                  </Text>
                }
              />
              <Box paddingTop={2}>
                <Text fontSize={20} lineHeight={1.3}>
                  Please confirm if you would like to proceed by clicking the confirm button below.
                </Text>
              </Box>
              <Box paddingTop={4}>
                <PurpleButton disabled={true}>
                  <Text color="#fff" fontWeight="bold">
                    Please wait...
                  </Text>
                </PurpleButton>
              </Box>
            </Box>
          </>
        )}

        {confirmedRenewStatus === 'confirmed' && (
          <>
            <Box paddingBottom={3}>
              <Text fontSize={26}>Renew Request Submitted</Text>
            </Box>

            <Box>
              <Text fontSize={20} lineHeight={1.3}>
                Thank you for your interest! One of our A&R representatives will contact you shortly
                with more details.
              </Text>
            </Box>
          </>
        )}
      </DialogModal>
    </Grid>
  );
};

export default InvitedDealItem;
