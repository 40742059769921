import React, { memo } from 'react';
// mui components
import {
  Grid,
  Autocomplete,
  Box,
  createFilterOptions,
  CircularProgress,
  FilterOptionsState,
} from '@mui/material';
// components
import BaseInput from './base-input';
// styles
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';

export interface IOption {
  label: string;
  value: string | number;
  imageUrl?: string;
}

const filterValueOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: IOption) => option.value.toString(),
});

const filterLabelOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: IOption) => option?.label || '',
});

const filterAnyValueOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: IOption) => option.value.toString(),
});

const filterAnyLabelOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: IOption) => option?.label || '',
});

interface OwnProps {
  options: IOption[];
  name: string;
  label: string;
  error: boolean;
  dark?: boolean;
  selectOnFocus?: boolean;
  helperText?: string;
  defaultValue?: string | number;
  value?: IOption | null;
  disableClearable?: boolean;
  loading?: boolean;
  disabled?: boolean;
  matchFrom?: 'start' | 'any';
  filterOptions?: (options: IOption[], state: any) => IOption[];
  size?: 'small' | 'medium' | string;
  onChange: (...event: any[]) => void;
  additionalOnChange?: (value: string | number) => void;
  handleChange?: (value: string) => void;
}

const AutocompleteInput: React.FC<OwnProps> = ({
  options,
  name,
  label,
  error,
  dark = false,
  helperText,
  matchFrom,
  onChange,
  additionalOnChange,
  handleChange,
  filterOptions,
  ...props
}) => {
  return (
    <Grid
      container
      sx={{
        '& li[aria-selected="true"]': {
          backgroundColor: `${colors.main.purple} !important`,
        },
        '& ul': {
          '& .Mui-focused': {
            backgroundColor: `${colors.main.purple} !important`,
          },
        },
        '& .MuiPaper-root': {
          backgroundColor: colors.main.blackLight,
        },
        '& .MuiAutocomplete-clearIndicator': {
          color: colors.text.dark.label,
        },
        '& .MuiAutocomplete-loading': {
          ...fontStyles.placeholder,
          fontWeight: 'normal',
          color: colors.text.dark.label,
        },
      }}
    >
      <Autocomplete
        {...props}
        freeSolo
        fullWidth
        getOptionLabel={(option) => {
          // @ts-ignore
          return option?.label || '';
        }}
        // @ts-ignore
        options={options}
        // @ts-ignore
        filterOptions={
          filterOptions ||
          ((option: IOption[], state) => {
            // a quick workaround to ignore the option with undefined label and value
            if (option.every(({ value }) => value === undefined)) {
              return [];
            }
            if (option.every(({ value }) => value === state.inputValue)) {
              if (matchFrom === 'any') {
                // @ts-ignore
                return filterAnyValueOptions(option, state);
              } else {
                // @ts-ignore
                return filterValueOptions(option, state);
              }
            }
            if (matchFrom === 'any') {
              // @ts-ignore
              return filterAnyLabelOptions(option, state);
            } else {
              // @ts-ignore
              return filterLabelOptions(option, state);
            }
          })
        }
        onChange={(_e, data) => {
          // @ts-ignore
          if (typeof data !== 'string' && data?.value) {
            // @ts-ignore
            onChange(data.value);
            // @ts-ignore
            additionalOnChange && additionalOnChange(data.value);
          } else {
            onChange(data);
          }
        }}
        renderInput={(params) => {
          return (
            <BaseInput
              {...params}
              name={name}
              dark={dark}
              label={label}
              error={error}
              variant="filled"
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {props.loading ? (
                      <Box sx={{ display: 'flex', transform: 'translateY(-50%)' }}>
                        <CircularProgress size={20} sx={{ color: colors.main.purple }} />
                      </Box>
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              onChange={(event) => {
                onChange(null);
                handleChange && handleChange(event.target.value);
              }}
            />
          );
        }}
        renderOption={(optionProps, option) => {
          // @ts-ignore
          const optionLabel: any = typeof option == 'string' ? option : option?.label || '';

          // @ts-ignore
          const optionImageUrl: any = option?.imageUrl;

          return (
            <Box
              component="li"
              {...optionProps}
              sx={{
                height: '52px',
                backgroundColor: dark ? colors.main.blackLight : colors.main.white,
                ...fontStyles.placeholder,
                fontWeight: 'normal',
                color: dark ? colors.text.dark.label : colors.text.light.body,
                '&:hover': {
                  backgroundColor: `${
                    dark ? colors.main.purple : colors.background.light.b3
                  } !important`,
                },
              }}
            >
              {optionImageUrl ? (
                <Box
                  component="img"
                  width="52px"
                  height="52px"
                  alt="artist image"
                  src={optionImageUrl}
                  sx={{
                    marginLeft: '-16px',
                    marginRight: '8px',
                    objectFit: 'cover',
                  }}
                />
              ) : null}
              {optionLabel}
            </Box>
          );
        }}
        ListboxComponent={React.forwardRef((listProps, ref) => (
          <Grid {...listProps} sx={{ padding: 'unset !important' }} />
        ))}
      />
    </Grid>
  );
};

export default memo(AutocompleteInput);
