export const featureLists = {
  local: {
    socialAnalytics: true,
  },
  test: {
    socialAnalytics: true,
  },
  production: {
    socialAnalytics: true,
  },
};

export const env = process.env.REACT_APP_ENV || 'production';

const features = {
  // @ts-ignore
  SOCIAL_ANALYTICS: featureLists[env].socialAnalytics,
};

export default features;
