// import NewDeal from 'pages/newdeal';
import Homepage from 'pages/homepage';
import Contact from 'pages/contact';

// auth
import SignIn from '../auth/sign-in';
// import SignUp from '../auth/sign-up';
import ForgotPassword from '../auth/forgot-password';
import ResetPassword from '../auth/reset-password';
// import Intro from '../auth/intro';
import Invitation from 'pages/auth/invitation';

/**
 * @todo: Rename profile to dashboard.
 */
import Profile from '../profile';
import Receipts from 'pages/receipts';

export const unauthorizedRoutes = [
  { path: '/', component: SignIn, exact: true },
  { path: '/contact', component: Contact, exact: true },
  // auth
  { path: '/sign-in', component: SignIn, exact: true },
  // { path: '/sign-up', component: SignUp, exact: true },
  { path: '/forgot-password', component: ForgotPassword, exact: true },
  { path: '/new-password', component: ResetPassword, exact: true },
  { path: '/invitation/:token', component: Invitation, exact: true },
];

export const authorizedRoutes = [
  { path: '/', component: Homepage, exact: true },
  { path: '/contact', component: Contact, exact: true },
  // auth
  // { path: '/intro', component: Intro, exact: true },
  // deal
  // { path: '/deal/:id', component: NewDeal, exact: true },
  { path: '/deals/:id', component: Profile, exact: true },
  // receipt parsing
  { path: '/receipts/:id', component: Receipts, exact: true },
  // profile
  { path: '/deals', component: Profile, exact: true },
  { path: '/profile', component: Profile, exact: true },
  { path: '/education', component: Profile, exact: true },
  { path: '/education/:slug', component: Profile, exact: true },
  { path: '/analytics', component: Profile, exact: true },
  { path: '/admin', component: Profile, exact: true },
  // set password for an account created from an invite
  { path: '/new-password', component: ResetPassword, exact: true },
];
