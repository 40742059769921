import React from 'react';
import { Box, Grid } from '@mui/material';
import { useUserData } from 'hooks/useUserData';
import Text from 'components/text';
import { ICourse, courses } from './courses';
import { Link } from 'react-router-dom';
import { BookOpenText, ListTodo } from 'lucide-react';

const CourseCard = ({ course }: { course: ICourse }) => {
  return (
    <Box
      component={Link}
      display="block"
      to={`/education/${course.slug}`}
      padding={2}
      paddingTop={3}
      paddingBottom={3}
      border={1}
      borderRadius={2}
      borderColor="#ccc"
      height={{
        xs: 'auto',
        md: 165,
        lg: 140,
      }}
      sx={{
        textDecoration: 'none',
        '&:hover': {
          borderColor: '#888',
          cursor: 'pointer',
          backgroundColor: '#f9f9f9',
        },
      }}
    >
      <Box display="flex" gap={2}>
        <Box display="flex" alignItems="flex-start">
          {course.type === 'embed' ? (
            <ListTodo size={28} color="#888" />
          ) : (
            <BookOpenText size={28} color="#888" />
          )}
        </Box>
        <Box>
          <Text fontSize={24} color="#555" letterSpacing={0.5} lineHeight={1.1}>
            {course.title}
          </Text>
        </Box>
      </Box>

      <Box>
        <Box>
          <Text color="#666" fontSize={18} paddingY={2}>
            {course.description}
          </Text>
        </Box>

        <Text color="#666" fontSize={20} paddingTop={1}>
          {course.lessons} {course.type === 'embed' ? 'Lessons' : 'Pages'}
        </Text>
      </Box>
    </Box>
  );
};

const Education: React.FC = () => {
  const { permissionsData } = useUserData();

  if (!permissionsData?.education) {
    return (
      <Box padding={3}>
        <Text>
          Sorry, the page you are looking for does not exist, please contact with us if you are
          facing an issues.
        </Text>
      </Box>
    );
  }

  return (
    <Grid container flexDirection="column">
      <Box padding={4} borderBottom={1} borderColor="#eee">
        <Text fontSize={24} paddingBottom={1}>
          Education
        </Text>
        <Text color="#666" fontSize={18}>
          Exclusive courses tailored to your growth!
        </Text>
      </Box>

      <Box display="flex" flexDirection="column" gap={4} padding={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <CourseCard course={courses['Social-Media-Full-Course']} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CourseCard course={courses['Social-Media-Best-Practices']} />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <CourseCard course={courses['Social-Media-Growth-Depth']} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CourseCard course={courses['Social-Media-Release-Promo']} />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Education;
