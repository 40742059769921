import React, { memo } from 'react';
// form
import { Controller, useFormContext } from 'react-hook-form';
// components
import Checkbox from 'components/checkbox';
// types
import { CheckboxProps } from '@mui/material';

interface OwnProps extends CheckboxProps {
  onCheck?: (val?: boolean, event?: MouseEvent) => void;
  [x: string]: any;
}

const CheckboxController: React.FC<OwnProps> = ({
  checked,
  name = '',
  defaultChecked = false,
  onCheck,
  disabled,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultChecked}
      render={({ field: { onChange, value } }) => (
        <Checkbox
          onChange={(e) => {
            onChange(e.target.checked);
            onCheck && onCheck(e.target.checked);
          }}
          onClick={(e) => e.stopPropagation()}
          checked={checked || value || false}
          disabled={disabled}
        />
      )}
    />
  );
};

export default memo(CheckboxController);
