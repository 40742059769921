export interface ICourse {
  type: string;
  slug: string;
  lessons: number;
  title: string;
  description: string;
}

export const courses = {
  'Social-Media-Full-Course': {
    type: 'embed',
    slug: 'Social-Media-Full-Course',
    lessons: 8,
    title: 'Social Media Onboarding',
    description:
      'This course is designed for beginners to social media, providing valuable tips, tricks, and clear action points to help establish and build their accounts.',
  },
  'Social-Media-Best-Practices': {
    type: 'embed',
    slug: 'Social-Media-Best-Practices',
    lessons: 6,
    title: 'Social Media Hacks & Quick Wins',
    description:
      'This course offers concise tips for optimizing content and accounts, addressing frequently asked questions.',
  },
  'Social-Media-Growth-Depth': {
    type: 'embed',
    slug: 'Social-Media-Growth-Depth',
    lessons: 6,
    title: 'Social Media Pro',
    description:
      'This course targets experienced social media users struggling to expand their fanbase, offering tips to attract a broader audience and deepen engagement.',
  },
  'Social-Media-Release-Promo': {
    type: 'embed',
    slug: 'Social-Media-Release-Promo',
    lessons: 3,
    title: 'Promote Your Release',
    description:
      'This short course teaches how to promote releases on social media, providing tips for effective content rollout.',
  },
  // 'Social-Media-Release-Promo': {
  //   type: 'pdf',
  //   slug: 'Social-Media-Release-Promo',
  //   lessons: 2,
  //   title: 'Promote Your Release',
  //   description:
  //     'This short course teaches how to promote releases on social media, providing tips for effective content rollout.',
  // },
};
